<template>
    <Header/>
    <Propertyheader/>
    <Propertyservice/>
    <Propertyicons/>
    <Footer/>
</template>

<script>

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Propertyservice from '@/components/Propertyservice.vue'
import Propertyicons from '@/components/Propertyicons.vue'
import Propertyheader from '@/components/Propertyheader.vue'

export default {
  components: {
     Header,
    Footer,
    Propertyservice,
    Propertyicons,
    Propertyheader
  }
}
</script>