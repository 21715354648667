<template>
    <section id="Propertyicon" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h4>Property Management</h4>
          </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-4 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
              <Image :fpath=" obj.path " class="Softwareimage"/>
              <h4 class="embservice">{{ obj.header }}</h4>
              <p class="embservice">{{ obj.caption }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'background/maintenance.png',
        header: 'REGULAR MAINTENANCE',
        caption: 'Maintenance at the grass root level of the property is our main focus. The plumbing, electricity and sewage connection are all taken care of.'
      },
      {
        path: 'background/SUPERVISING-RESPONSIBILITIES.png',
        header: 'SUPERVISING RESPONSIBILITIES',
        caption: 'We supervise our workforce and their roles as part of regular maintenance. It is for the overall advancement of the property benefits.'
      },
      {
        path: 'background/MAINTAINING-RECORDS.png',
        header: 'MAINTAINING RECORDS',
        caption: 'Records pertaining to all the changes and expenditure invested on the property is kept under lenses at all time.'
      },
      {
        path: 'background/OVERSEEING-BUDGET.png',
        header: 'OVERSEEING BUDGET',
        caption: 'We maintain the overall budget for the losses and the turn overs.'
      },
      {
        path: 'background/RENTING-PROPERTY.png',
        header: 'RENTING PROPERTY',
        caption: 'The best renting scheme is decided by us, taking into consideration all the local areas and their rent procedures.'
      },
      {
        path: 'background/LEASING-PROPERTY.png',
        header: 'LEASING PROPERTY',
        caption: 'We ensure leasing out a property in turn benefits the owner and oversee the maintenance and the change of the lease period.'
      }
    ]

  })
    
}
</script>
<style>
.services .icon-box img {
    float: left;
    margin-top: 10px;
  }
 
img.Softwareimage {
    width: 15%;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Propertyicon {
    padding: 50px 30px 10px 30px;
}


.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
    margin-top: 10px;
}
.embservice{
  margin-left: 70px;
}
.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>